import { I2PEnvironment } from '@price-for-profit/micro-services';
import { Dashboard } from '@mui/icons-material';
import InsertChartOutlinedOutlinedIcon from '@mui/icons-material/InsertChartOutlinedOutlined';

const routeConfig: EnvironmentNavigation[] = [
    {
        application: {
            applicationName: 'Analytics',
            env: {
                local: {
                    applicationId: '98e78355-79b1-4edf-8830-e71d1a17a366',
                    deployedUrl: 'https://localhost:5001/apps/account',
                },
                dev: {
                    applicationId: '98e78355-79b1-4edf-8830-e71d1a17a366',
                    deployedUrl: 'https://cli-templates.dev.insight2profit.com/apps/account',
                },
                sandbox: {
                    applicationId: '',
                    deployedUrl: 'https://cli-templates.sandbox.insight2profit.com/apps/account',
                },
                prod: {
                    applicationId: '',
                    deployedUrl: 'https://cli-templates.insight2profit.com/apps/account',
                },
            },
        },
        links: [
            {
                title: 'Analytics Dashboard',
                url: '/analytics',
                icon: <InsertChartOutlinedOutlinedIcon sx={{ color: 'primary.light' }} />,
            },
        ],
    },
    {
        application: {
            applicationName: 'Base App',
            env: {
                local: {
                    applicationId: '562a2309-4c8c-4ec1-8cdb-a1be51901898',
                    deployedUrl: 'https://localhost:5003/apps/base',
                },
                dev: {
                    applicationId: '562a2309-4c8c-4ec1-8cdb-a1be51901898',
                    deployedUrl: 'https://cli-templates.dev.insight2profit.com/apps/base',
                },
                sandbox: {
                    applicationId: '',
                    deployedUrl: 'https://cli-templates.sandbox.insight2profit.com/apps/base',
                },
                prod: {
                    applicationId: '',
                    deployedUrl: 'https://cli-templates.insight2profit.com/apps/base',
                },
            },
        },
        links: [
            {
                title: 'Base Drive App',
                url: '/demo',
                icon: <Dashboard sx={{ color: 'primary.light' }} />,
            },
        ],
    },
];

interface NavigationLink {
    title: string;
    url: string;
    icon?: JSX.Element;
}

interface EnvironmentNavigation {
    application: {
        applicationName: string;
        env: Record<Exclude<I2PEnvironment, 'model'> | 'local', { applicationId: string; deployedUrl: string }>;
    };
    links: NavigationLink[];
}

interface DriveAccountNavigation {
    application: {
        applicationId: string;
        applicationName: string;
        deployedUrl: string;
    };
    links: NavigationLink[];
    local?: boolean;
}

interface BuildDriveAccountNavigationProps {
    applicationId: string;
    environment: Exclude<I2PEnvironment, 'model'>;
    useLocal?: boolean;
}

export function buildDriveAccountNavigation({
    applicationId,
    environment,
    useLocal,
}: BuildDriveAccountNavigationProps): DriveAccountNavigation[] {
    const localEnvironment = environment === 'dev' && useLocal ? 'local' : environment;

    return routeConfig.map<DriveAccountNavigation>(({ links, application }) => {
        const envConfig = application.env[localEnvironment];
        return {
            application: {
                applicationName: application.applicationName,
                ...envConfig,
            },
            links,
            local: applicationId === envConfig.applicationId,
        };
    });
}

export const showHamburger = routeConfig.length > 1;
