import {
    AppLogo,
    AuthenticationRequired,
    DemoResolver,
    LoginLayout,
    LoginPage,
    NavigationLayout,
    UnauthorizedPage,
    useService,
} from '@insight2profit/drive-app';
import { Navigate, Outlet, useRoutes } from 'react-router-dom';
import { ROUTES } from 'routing';
import clientLogo from 'assets/insight-logo.png';
// import loginClientLogoSrc from '../assets/path-to-client-logo.png;
import insightDriveAccountLogo from 'assets/insight-drive-account-logo.svg';

const isProd = process.env.NODE_ENV === 'production';

const headerClientLogo = <AppLogo src={clientLogo} alt='client logo' />;
const loginClientLogo = <AppLogo src={`${process.env.PUBLIC_URL}/favicon-32x32.png`} alt='client login logo' />;
// const loginClientLogo = <AppLogo src={loginClientLogoSrc} alt='insight client login logo' />;
const insightLoginLogo = <AppLogo src={insightDriveAccountLogo} alt='insight logo' maxHeight='30px' />;
const driveToProfitLogo = (
    <AppLogo src={`${process.env.PUBLIC_URL}/DRIVEtoPROFIT_Color.png`} alt='drive to profit logo' maxHeight='50px' />
);

export function AppRoutes() {
    return useRoutes([
        {
            element: <Login />,
            children: [
                {
                    path: ROUTES.login,
                    element: (
                        <LoginPage
                            tabTitle='Cli templates base Login'
                            insightLoginLogo={insightLoginLogo}
                            clientLogo={loginClientLogo}
                            loginRedirectRoute={ROUTES.demo}
                        />
                    ),
                },
            ],
        },
        {
            element: <Private />,
            children: [
                {
                    path: ROUTES.demo,
                    element: <DemoResolver />,
                },
            ],
        },
        {
            path: ROUTES.unauthorized,
            element: <Unauthorized />,
        },
        {
            path: '*',
            element: <Navigate to={ROUTES.login} replace />,
        },
    ]);
}

function Unauthorized() {
    const {
        appConfig: { serviceBaseUrl },
    } = useService();

    return <UnauthorizedPage logo={headerClientLogo} driveLogo={insightLoginLogo} serviceBaseUrl={serviceBaseUrl} />;
}

function Login() {
    const {
        appConfig: { serviceBaseUrl },
    } = useService();

    return (
        <LoginLayout serviceBaseUrl={serviceBaseUrl}>
            <Outlet />
        </LoginLayout>
    );
}

function Private() {
    const {
        appConfig: { serviceBaseUrl, appId },
    } = useService();
    return (
        <AuthenticationRequired>
            <NavigationLayout
                useLocal={!isProd}
                applicationId={appId}
                logo={headerClientLogo}
                serviceBaseUrl={serviceBaseUrl}
                footerLogo={driveToProfitLogo}
            >
                <Outlet />
            </NavigationLayout>
        </AuthenticationRequired>
    );
}
