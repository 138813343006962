import { Menu } from '@mui/icons-material';
import { Box, Drawer, Stack } from '@mui/material';
import React from 'react';
import { SUPPORT_PORTAL_LINK } from '../../constants';
import { Header } from '../header';
import { LeftNavigation } from './left-navigation';
import { showHamburger } from './navigation-config';
import { useNavigationState } from './use-navigation-state';
import { AppLogo } from '../logos';
import { useInsightEnvironment } from '../../hooks';

export const DRAWER_WIDTH = 271;

export interface NavigationProps {
    logo: JSX.Element;
    footerLogo: JSX.Element;
    serviceBaseUrl: string;
    applicationId: string;
    toolbar?: {
        rightComponent?: React.ReactNode;
        leftComponent?: React.ReactNode;
    };
    navigation?: {
        trainingGuideLink?: string;
    };
    useLocal: boolean;
}

export function Navigation({
    children,
    serviceBaseUrl,
    logo,
    applicationId,
    useLocal,
    toolbar = {},
    navigation = {},
    footerLogo,
}: React.PropsWithChildren<NavigationProps>) {
    const { state, toggleLeft } = useNavigationState();
    const environment = useInsightEnvironment({ serviceBaseUrl });
    const loginClientLogo = <AppLogo src={`${process.env.PUBLIC_URL}/favicon-32x32.png`} alt='client login logo' />;
    const insightLogo = (
        <Box component='img' src={`${process.env.PUBLIC_URL}/insigth-logo-color.svg`} alt='insight logo' />
    );
    const insightLogoWaterMartk = (
        <Box
            component='img'
            src={`${process.env.PUBLIC_URL}/insight-logo-watermark${environment !== 'prod' ? '-white' : ''}.svg`}
            sx={{ height: '9px' }}
            alt='insight logo'
        />
    );

    return (
        <>
            <Header serviceBaseUrl={serviceBaseUrl}>
                <Box>
                    <Stack direction='row' spacing={1} justifyContent='baseline' alignItems='center' gap={1}>
                        {showHamburger && (
                            <Menu
                                onClick={toggleLeft}
                                sx={{
                                    color: environment !== 'prod' ? 'common.white' : 'primary.main',
                                    fontSize: '18px',
                                }}
                            />
                        )}
                        <Box display={'flex'} sx={{ height: '10px' }}>
                            {loginClientLogo || insightLogo}
                        </Box>
                        <Box display={'flex'}>{insightLogoWaterMartk}</Box>
                        {toolbar?.leftComponent}
                    </Stack>
                </Box>
            </Header>
            <Drawer onClose={toggleLeft} anchor='left' open={state.left}>
                <Box width={DRAWER_WIDTH} height={'100%'}>
                    <LeftNavigation
                        onClose={toggleLeft}
                        useLocal={useLocal}
                        applicationId={applicationId}
                        serviceBaseUrl={serviceBaseUrl}
                        footerLogo={footerLogo}
                        drawerWidth={DRAWER_WIDTH}
                        trainingGuideLink={navigation.trainingGuideLink}
                        supportPortalLink={SUPPORT_PORTAL_LINK}
                        clientLogo={loginClientLogo}
                    />
                </Box>
            </Drawer>
            <Box component='main'>{children}</Box>
        </>
    );
}
