import { AxiosInstance, EventService } from '@price-for-profit/micro-services';
import { AppConfig } from 'shared/types';

export function createServiceContext(appConfig: AppConfig, httpClient: AxiosInstance): app.ServiceContext<AppConfig> {
    const eventService = new EventService(httpClient);
    return {
        appConfig,
        eventService,
    };
}
