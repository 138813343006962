import { Help, School } from '@mui/icons-material';
import { Box, Button, ListItemButton, ListItemIcon, ListItemText, Menu, Typography } from '@mui/material';
import { useMenu } from '../../hooks/use-menu';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const SUPPORT_MENU_BUTTON = `support-menu-button`;
const SUPPORT_MENU_NAME = `support-menu`;

interface SupportMenuProps {
    supportPortalLink?: string;
    trainingGuideLink?: string;
}
export function SupportMenuLeftNavigation({ supportPortalLink, trainingGuideLink }: SupportMenuProps) {
    const [anchorEl, openMenu, closeMenu, open] = useMenu();

    return (
        <>
            <Menu
                id={SUPPORT_MENU_NAME}
                anchorEl={anchorEl}
                open={open}
                onClose={closeMenu}
                MenuListProps={{
                    'aria-labelledby': SUPPORT_MENU_BUTTON,
                }}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        ml: 1.5,
                    },
                }}
            >
                {supportPortalLink && (
                    <ListItemButton divider component='a' href={supportPortalLink} target='_blank'>
                        <ListItemIcon>
                            <Help sx={{ color: 'primary.light' }} />
                        </ListItemIcon>
                        <ListItemText primary='Request Support' />
                    </ListItemButton>
                )}

                {trainingGuideLink && (
                    <ListItemButton divider component='a' href={trainingGuideLink} target='_blank'>
                        <ListItemIcon>
                            <School sx={{ color: 'primary.light' }} />
                        </ListItemIcon>
                        <ListItemText primary='View Training Guide' />
                    </ListItemButton>
                )}
            </Menu>
            <Button
                fullWidth
                id={SUPPORT_MENU_BUTTON}
                aria-controls={open ? SUPPORT_MENU_NAME : undefined}
                aria-haspopup='true'
                aria-expanded={open ? 'true' : undefined}
                sx={{ justifyContent: 'space-between', padding: '17px 16px' }}
                onClick={openMenu}
            >
                <SupportAgentIcon sx={{ color: 'primary.light' }} />
                <Typography sx={{ color: 'black', fontSize: '14px' }}>Get customer support</Typography>
                <KeyboardArrowRightIcon sx={{ color: 'black' }} />
            </Button>
        </>
    );
}
