import { AccountCircle, ExpandMore } from '@mui/icons-material';
import { Box, Button, List, ListItemIcon, ListItemText, Typography, styled } from '@mui/material';
import { useInsightEnvironment } from '../../hooks/use-insight-environment';
import { buildDriveAccountNavigation } from './navigation-config';
import { NavigationLink } from './navigation-link';
import React from 'react';
import { useUser } from '../../hooks';
import { useMsal } from '@azure/msal-react';
import { SupportMenu } from '../header';
import LogoutIcon from '@mui/icons-material/Logout';
import { blueGrey, grey } from '@mui/material/colors';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { SupportMenuLeftNavigation } from '../header/support-menu-left-navigation';

interface LeftNavigationProps {
    applicationId: string;
    serviceBaseUrl: string;
    useLocal: boolean;
    onClose(): void;
    footerLogo: JSX.Element;
    clientLogo: JSX.Element;
    drawerWidth: number;
    trainingGuideLink?: string;
    supportPortalLink?: string;
}

const DEFAULT_SUPPORT_PORTAL_LINK = 'https://insight2profit.atlassian.net/servicedesk/customer/portal/25';

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
    ({ theme }) => ({
        border: `1px solid ${theme.palette.divider}`,
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '& .Mui-expanded': {
            background: blueGrey[50],
        },
        '&:before': {
            display: 'none',
        },
    })
);

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
    backgroundColor: grey[400],
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        color: theme.palette.secondary.main,
    },
    '& .Mui-expanded': {
        background: blueGrey[50],
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: `1px solid ${grey[400]}`,
}));

export function LeftNavigation({
    applicationId,
    serviceBaseUrl,
    useLocal,
    onClose,
    footerLogo,
    clientLogo,
    drawerWidth,
    trainingGuideLink,
    supportPortalLink,
}: LeftNavigationProps) {
    const environment = useInsightEnvironment({ serviceBaseUrl });
    const user = useUser();
    const { instance } = useMsal();

    const logout = () => {
        instance.logout();
    };

    const leftNavConfig = buildDriveAccountNavigation({
        applicationId,
        environment,
        useLocal,
    });
    const [state, setState] = React.useState(() => {
        return leftNavConfig.reduce<Record<string, boolean>>((state, config) => {
            state[config.application.applicationId] = Boolean(config.local);
            return state;
        }, {});
    });

    const handleExpandChange = (id: string) => {
        return () => {
            setState(prev => ({ ...prev, [id]: !prev[id] }));
        };
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <Box sx={{ height: '82px', padding: '25px 20px 15px 20px' }}>{clientLogo}</Box>
            <Typography sx={{ color: grey['700'], padding: '0px 8px 18px 16px' }} variant='body2'>
                Drive Applications
            </Typography>
            <Box component='nav' sx={{ overflow: 'auto', flex: '1 1 75%' }}>
                {leftNavConfig.map(({ local, application, links }) => (
                    <Accordion
                        key={application.applicationId}
                        expanded={state[application.applicationId]}
                        onChange={handleExpandChange(application.applicationId)}
                        sx={{ padding: 0 }}
                    >
                        <AccordionSummary
                            sx={{ backgroundColor: 'background.paper', padding: '0 4px' }}
                            expandIcon={<ExpandMore />}
                        >
                            {Boolean(links[0].icon) && <ListItemIcon>{links[0].icon}</ListItemIcon>}
                            <Typography>{application.applicationName}</Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ p: 0 }}>
                            <List disablePadding>
                                {links.map(({ title, url, icon }) => (
                                    <NavigationLink
                                        onClose={onClose}
                                        key={`${application.applicationId}${url}`}
                                        local={Boolean(local)}
                                        deployedUrl={application.deployedUrl}
                                        url={url}
                                    >
                                        <ListItemText inset={!Boolean(icon)} primary={title} />
                                    </NavigationLink>
                                ))}
                            </List>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Box>
            <Box sx={{ paddingBottom: '20px' }}>
                <Typography
                    sx={{ color: grey['700'], padding: '18px 16px', backgroundColor: blueGrey[50] }}
                    variant='body2'
                >
                    User Resources
                </Typography>
                <SupportMenuLeftNavigation
                    supportPortalLink={supportPortalLink || DEFAULT_SUPPORT_PORTAL_LINK}
                    trainingGuideLink={trainingGuideLink}
                />
            </Box>

            <Box
                sx={{
                    flex: '0 0 auto',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    justifySelf: 'end',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                        width: drawerWidth,
                        overflow: 'hidden',
                        paddingLeft: '16px',
                        paddingBottom: 1,
                    }}
                >
                    <ListItemIcon sx={{ minWidth: 0 }}>
                        <AccountCircle />
                    </ListItemIcon>
                    <ListItemText primary={user?.displayName} secondary={user?.email} />
                </Box>
                <Button
                    fullWidth
                    variant='contained'
                    sx={{
                        borderRadius: '0px',
                        justifyContent: 'flex-start',
                        gap: 2,
                        padding: '15px 8px',
                    }}
                    onClick={logout}
                    color={'info'}
                >
                    <LogoutIcon />
                    <Box sx={{ fontWeight: 'bold' }}>Logout</Box>
                </Button>
            </Box>
        </Box>
    );
}
